import Layout from '@/components/layout';
import PractitionersSearchSection from '@/components/practitioners/search-section';
import { Link } from 'gatsby';
import React from 'react';

const ListPractitionersByRegionProvinceCity = ({
  pageContext: { practitioners },
}) => {
  let currentRegion;
  let currentProvince;
  let currentCity;
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <h1 className="title is-4 has-text-centered is-primary">
            Liste de nos thérapeutes classés par Région / Province / ville
          </h1>
        </div>
      </section>
      <PractitionersSearchSection />
      <section className="section">
        <div className="container">
          {practitioners.map((currentPractitioner) => {
            const newRegion =
              currentRegion !== currentPractitioner.location.region;
            if (newRegion) currentRegion = currentPractitioner.location.region;
            const newProvince =
              currentProvince !== currentPractitioner.location.province;
            if (newProvince)
              currentProvince = currentPractitioner.location.province;
            const newCity = currentCity !== currentPractitioner.location.city;
            if (newCity) currentCity = currentPractitioner.location.city;
            return (
              <>
                {newRegion && currentRegion && (
                  <>
                    <hr className="mt-6" />
                    <h2 className="title is-2 mt-6">{currentRegion}</h2>
                  </>
                )}
                {newProvince && currentProvince && (
                  <h3 className="title is-3 mt-5">{currentProvince}</h3>
                )}
                {newCity && currentCity && (
                  <h4 className="title is-4 mt-4">{currentCity}</h4>
                )}
                {currentPractitioner.title && (
                  <>
                    <h5 className="mt-3 title is-5">
                      <Link
                        to={`${currentPractitioner.meta.websiteSlug}#content`}
                      >
                        {currentPractitioner.title}
                      </Link>
                    </h5>
                    {currentPractitioner.category.title && (
                      <div className="subtitle is-6">
                        {currentPractitioner.category.title}{' '}
                        {currentPractitioner.content.accompanimentsDomains
                          .length ? (
                          <>
                            (
                            {currentPractitioner.content.accompanimentsDomains.join(
                              ', ',
                            )}
                            )
                          </>
                        ) : undefined}
                      </div>
                    )}
                    {currentPractitioner.content.accompanimentsDomains && (
                      <p className="mb-6" />
                    )}
                  </>
                )}
              </>
            );
          })}
        </div>
      </section>
    </Layout>
  );
};
export default ListPractitionersByRegionProvinceCity;
